var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-overlay",
        { attrs: { value: _vm.computedIsLoading, "z-index": "50" } },
        [
          _c(
            "div",
            { staticClass: "mx-auto", staticStyle: { width: "64px" } },
            [
              _c("v-progress-circular", {
                attrs: {
                  indeterminate: "",
                  size: 64,
                  width: 7,
                  color: "primary"
                }
              })
            ],
            1
          ),
          _vm.restartAnimation
            ? _c(
                "p",
                {
                  staticClass:
                    "text-center mt-2 font-italic text-h5 fade-animation",
                  staticStyle: { "max-width": "500px" }
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        _vm._f("ellipses")(
                          _vm._f("upperCase")(_vm.computedLoadingMessage)
                        )
                      ) +
                      "\n    "
                  )
                ]
              )
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }